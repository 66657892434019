<template>
  <div>
    <div class="container">
      <div class="card">

        <div v-if="song" class="mb-4">
          <MusicPlayer
            :filename="song.filename"
            :extension="extension"
            :name="song.name"
            :bg-index="index"
            :analyzer-smoothing-time-constant="analyzerSmoothingTimeConstant"
            :analyzer-min-decibels="analyzerMinDecibels"
            :analyzer-max-decibels="analyzerMaxDecibels"
            :fft-size="fftSize"
            :amp-scale="ampScale"
            :freq-scale="freqScale"
            :show-fps="showFps"
            :fps-limit="fpsLimit"
            :analyzer-mode="analyzerMode"
            :show-peaks="showPeaks"
            :window-size="windowSize"
            @loaded="loaded"
          ></MusicPlayer>
        </div>

        <div v-if="!loading" class="p-4">
          <div class="row">
            <div class="col-4">
              <div class="mb-4 d-flex align-items-center">
                <button class="btn btn-flat mr-2" :class="{ active: extension==='mp3' }" @click="extension='mp3'">MP3</button>
                <button class="btn btn-flat mr-2" :class="{ active: extension==='flac' }" @click="extension='flac'">FLAC</button>
              </div>
            </div>

            <div class="col-4">
              <div>
                <label>Smoothing Time Constant {{ analyzerSmoothingTimeConstant }}</label>
                <input type="range" min="0" max="1" step="0.01" v-model.number.lazy="analyzerSmoothingTimeConstant" class="form-control" />
              </div>

              <div>
                <label>Min Decibels {{ analyzerMinDecibels }}</label>
                <input type="range" min="-100" :max="analyzerMaxDecibels" step="1" v-model.number.lazy="analyzerMinDecibels" class="form-control" />
              </div>

              <div>
                <label>Max Decibels {{ analyzerMaxDecibels }}</label>
                <input type="range" :min="analyzerMinDecibels" max="0" step="1" v-model.number.lazy="analyzerMaxDecibels" class="form-control" />
              </div>

              <label>FFT Size</label>
              <select class="form-control" v-model.number="fftSize">
                <option v-for="fs in fftSizes" :value="fs">{{ fs }}</option>
              </select>

              <hr>

              <div>
                <label>WindowSize {{ windowSize }}</label>
                <input type="range" min="0.01" max="10" step="0.01" v-model.number.lazy="windowSize" class="form-control" />
              </div>

            </div>

            <div class="col-4">
              <label>Waveform Amplitutde Scale</label>
              <select class="form-control" v-model="ampScale">
                <option v-for="wvs in ampScaleOptions" :value="wvs">{{ wvs }}</option>
              </select>

              <label>Frequency Scale</label>
              <select class="form-control" v-model="freqScale">
                <option v-for="wvs in freqScaleOptions" :value="wvs">{{ wvs }}</option>
              </select>

              <label>Analyzer Mode</label>
              <select class="form-control" v-model="analyzerMode">
                <option v-for="am in analyzerModeOptions" :value="am.value">{{ am.text }}</option>
              </select>

              <div>
                <label class="py-2">
                  <input type="checkbox" v-model="showPeaks">
                  Show Peaks
                </label>
              </div>

              <div>
                <label class="py-2">
                  <input type="checkbox" v-model="showFps">
                  Show FPS
                </label>
              </div>

              <div v-if="showFps">
                <label>FPS Limit {{ fpsLimit }}</label>
                <input
                  type="range"
                  min="0"
                  max="144"
                  step="1"
                  v-model.number.lazy="fpsLimit"
                  class="form-control"
                />
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>
</template>

<script>

import MusicPlayer from './music-player'

import store from '../store'

export default {
  components: { MusicPlayer },
  props: {
    filename: { type: String, required: true },
  },
  data() {
    return {
      loading: true,

      extension: 'mp3',
      analyzerSmoothingTimeConstant: 0.5,
      analyzerMinDecibels: -90,
      analyzerMaxDecibels: -10,
      fftSize: 8192,
      fftSizes: [128, 256, 512, 1024, 2048, 4096, 8192, 16384, 32768],
      ampScale: 'log',
      freqScale: 'log',
      freqScaleOptions: ['log', 'linear', 'mel'],
      ampScaleOptions: ['log', 'linear'],
      showFps: true,
      fpsLimit: 60,
      analyzerMode: 10,
      analyzerModeOptions: [
        { value: 1, text: '1 Notes per Band' },
        { value: 2, text: '2 Notes per Band' },
        { value: 3, text: '3 Notes per Band' },
        { value: 4, text: '4 Notes per Band' },
        { value: 5, text: '6 Notes per Band' },
        { value: 6, text: '7 Notes per Band' },
        { value: 7, text: '12 Notes per Band' },
        { value: 8, text: '24 Notes per Band' },
        { value: 10, text: 'Line' },
      ],
      showPeaks: true,
      windowSize: 4,
    }
  },
  computed: {
    song() {
      const song = store.getters['songs'].find(s => s.filename === this.filename)
      return song
    },
    index() {
      return store.getters['songs'].indexOf(this.song)
    }
  },
  created() {
  },
  methods: {
    loaded() {
      this.loading = false
    }
  }
}

</script>
